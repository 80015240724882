import { default as abuseReportsSVEeKN6XgRMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93JdWkqEMuoTMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index0X7VAXMEICMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/index.vue?macro=true";
import { default as moderationDWyCRa34xgMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/moderation.vue?macro=true";
import { default as pendingBansoDffUhQVYZMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/pendingBans.vue?macro=true";
import { default as profilesCOVvpGRApdMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/profiles.vue?macro=true";
import { default as expensesYBfYFxbmCwMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexzgD2bIA5IYMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/index.vue?macro=true";
import { default as overview0wS07mk7eeMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingcxqWfTAcxAMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/translations/awaiting.vue?macro=true";
import { default as missinga49JLEa1DSMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/translations/missing.vue?macro=true";
import { default as usersLIxBkPEs7VMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/users.vue?macro=true";
import { default as apioQXTiZZgDrMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/api.vue?macro=true";
import { default as _91slug_930XNCNOYa82Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/blog/[slug].vue?macro=true";
import { default as indexLSaaB4UqlzMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93iZW2ULqkPWMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93I5evGFtjlqMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminLFyhxwXCmIMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/census/admin.vue?macro=true";
import { default as index94yWUtylK2Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/census/index.vue?macro=true";
import { default as contact3e83y9N4O9Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/contact.vue?macro=true";
import { default as designKNrnGs2kTKMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/design.vue?macro=true";
import { default as englishWdcGbrSbFLMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/english.vue?macro=true";
import { default as faqpWAASU03L3Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/faq.vue?macro=true";
import { default as inclusiveJhDEG2TvkDMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/inclusive.vue?macro=true";
import { default as index2QpZAr8zz7Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/index.vue?macro=true";
import { default as licenseQcCx8bZcuqMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/license.vue?macro=true";
import { default as academicYRODDkgFiFMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/academic.vue?macro=true";
import { default as indexqDWspbT0iZMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/index.vue?macro=true";
import { default as mediaqMjB9eyEUZMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/media.vue?macro=true";
import { default as translinguisticsCx1icPs2bIMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/translinguistics.vue?macro=true";
import { default as zinezeZjKij2cFMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/zine.vue?macro=true";
import { default as namesFKN7GbhL3sMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/names.vue?macro=true";
import { default as index6NLpcmAunuMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/nouns/index.vue?macro=true";
import { default as templatesUEJVxyMBuHMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/nouns/templates.vue?macro=true";
import { default as peopleuQf2ip6K6GMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/people.vue?macro=true";
import { default as privacyAVYRGOCnQkMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/privacy.vue?macro=true";
import { default as _91username_93bC3DjkuSuwMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_9342dF6a8cE1Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/card-[username].vue?macro=true";
import { default as editor22QCD2uh6EMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/editor.vue?macro=true";
import { default as anyG6SlI4BHJnMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/any.vue?macro=true";
import { default as askmnbHaWtxGrMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/ask.vue?macro=true";
import { default as avoidingl8M2fgVmyUMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/avoiding.vue?macro=true";
import { default as indexwHSwMZEYaFMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/index.vue?macro=true";
import { default as mirrorxAoGSLdjqCMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/mirror.vue?macro=true";
import { default as pronounYU95mmt0xxMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/pronoun.vue?macro=true";
import { default as sourceseXGzyeMrGoMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/sources.vue?macro=true";
import { default as teammyypHHCPbhMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/team.vue?macro=true";
import { default as terminologyfdXu289ogSMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/terminology.vue?macro=true";
import { default as termspo1kXmAnijMeta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/terms.vue?macro=true";
import { default as usertFSPfO9cm4Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/user.vue?macro=true";
import { default as workshopsAKMamUcNi5Meta } from "/home/admin/www/en.pronouns.page/release/20241214175511/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsSVEeKN6XgRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansoDffUhQVYZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apioQXTiZZgDrMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_930XNCNOYa82Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexLSaaB4UqlzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93iZW2ULqkPWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93I5evGFtjlqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminLFyhxwXCmIMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: index94yWUtylK2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact3e83y9N4O9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishWdcGbrSbFLMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqpWAASU03L3Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveJhDEG2TvkDMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicYRODDkgFiFMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexqDWspbT0iZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaqMjB9eyEUZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsCx1icPs2bIMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinezeZjKij2cFMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesFKN7GbhL3sMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index6NLpcmAunuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesUEJVxyMBuHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleuQf2ip6K6GMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyAVYRGOCnQkMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93bC3DjkuSuwMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_9342dF6a8cE1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor22QCD2uh6EMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyG6SlI4BHJnMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askmnbHaWtxGrMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingl8M2fgVmyUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexwHSwMZEYaFMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorxAoGSLdjqCMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounYU95mmt0xxMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourceseXGzyeMrGoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teammyypHHCPbhMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyfdXu289ogSMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termspo1kXmAnijMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: usertFSPfO9cm4Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsAKMamUcNi5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241214175511/pages/workshops.vue")
  }
]